<div
  (resized)="onResize()"
  class="chart-container"
>
  @if (!overlayChart) {
    <ngx-charts-line-chart
      [rangeFillOpacity]="isRanged ? 0.1 : undefined"
      [results]="ngxChart.chartData"
      [scheme]="ngxChart.defaultColorScheme"
      [legend]="false"
      [xAxisLabel]="ngxChart.labels.secondGroupBy"
      [yAxisLabel]="yAxisLabel"
      [xAxis]="!isSpark && showXAxis"
      [yAxis]="!isSpark && showYAxis"
      [showXAxisLabel]="!isSpark"
      [showYAxisLabel]="!isSpark && !!yAxisLabel"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="yAxisTickFormatting || ngxChart.labels.counterFormatter"
      [trimYAxisTicks]="!yAxisTickFormatting"
      [animations]="true"
      [tooltipDisabled]="isSpark"
      [customColors]="ngxChart.customColors"
      [referenceLines]="referenceLines"
      [showRefLabels]="showRefLabels"
      [showRefLines]="showRefLines"
      [timeline]="showTimeline"
      [dpaChartMarkers]="markers"
      [dpaChartMarkerTooltipTemplate]="markerTooltipTemplate"
      [yScaleMax]="yScaleMax"
      [yScaleMin]="yScaleMin"
      [trimXAxisTicks]="trimXAxisTicks"
      [yAxisTicks]="yAxisTicks"
      [showMaxDomain]="showMaxDomain"
      [roundDomains]="roundDomains"
      (select)="onSelect($event)"
      (onMarkerClick)="handleMarkerClick($event)"
    >
      <ng-template
        #tooltipTemplate
        let-model="model"
      >
        @if (model) {
          <ng-container
            [ngTemplateOutlet]="stackedTooltip"
            [ngTemplateOutletContext]="{ ngxModel: model, tooltip: ngxChart.getTooltip(model) }"
          />
        }
      </ng-template>
      <ng-template
        #seriesTooltipTemplate
        let-model="model"
      >
        @if (model) {
          <ng-container
            [ngTemplateOutlet]="seriesTooltip"
            [ngTemplateOutletContext]="{ usingFakeGroupBy: ngxChart.usingFakeGroupBy, tooltipItems: model }"
          />
        }
      </ng-template>
    </ngx-charts-line-chart>
  } @else {
    <dpa-line-overlay-chart
      #chart
      [scheme]="ngxChart.defaultColorScheme"
      [schemeForOverlay]="overlayChart.defaultColorScheme"
      [results]="ngxChart.chartData"
      [overlayResults]="overlayChart.chartData"
      [customColorsForOverlay]="overlayChart.customColors"
      [xAxisLabel]="ngxChart.labels.firstGroupBy"
      [yAxisLabel]="yAxisLabel"
      [yAxisRightLabel]="overlayChart.labels.counter"
      [gradient]="false"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="true"
      [showYAxisLabel]="!!yAxisLabel"
      [animations]="true"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [trimXAxisTicks]="true"
      [xAxisTickFormatting]="ngxChart.labels.xAxisDateFormatter"
      [yAxisTickFormatting]="ngxChart.labels.counterFormatter"
      [customColors]="ngxChart.customColors"
      [showDataLabel]="true"
      [dataLabelFormatting]="ngxChart.labels.counterFormatter"
      [tooltipTemplate]="overlayTooltipTemplate"
      [yScaleMax]="yScaleMax"
      [focussedSequence]="focussedSequence"
      (select)="onSelect($event)"
    />
  }
</div>
